<template>
  <div id="container">
    <div class="top">
      <el-button type="primary" @click="addObj.flag = true"
        >添加轮播图</el-button
      >
    </div>
    <el-table
      stripe
      :data="
        tableData.filter(
          (data) =>
            !search ||
            String(data.phone).toLowerCase().includes(search.toLowerCase())
        )
      "
      style="width: 100%"
      max-height="730px"
      v-loading="loading"
    >
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="img" label="轮播图">
        <template slot-scope="scope">
          <img :src="$mainUrl(scope.row.img)" alt style="width: 60%" />
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            @click.native.prevent="handleDelete(scope.row.id)"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加 -->
    <el-dialog
      title="添加轮播图"
      :visible.sync="addObj.flag"
      :before-close="addclear"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="标题:">
          <el-input v-model="addObj.title" type="text" size="small"></el-input>
        </el-form-item>
        <el-form-item label="选择图片:">
          <el-upload
            class="upload-demo"
            :action="$mainUrl('api/bannerAdd')"
            ref="upload"
            :data="{
              title: addObj.title,
              name: this.$store.state.userInfo.name,
              token: this.$store.state.userInfo.token,
            }"
            :file-list="addObj.files"
            :on-success="addyes"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addclear()">取 消</el-button>
        <el-button type="primary" @click="addTrue()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑信息"
      :visible.sync="changeObj.flag"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <el-form label-position="left" label-width="100px">
        <el-form-item label="标题:">
          <el-input
            v-model="changeObj.title"
            type="text"
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择图片:">
          <el-upload
            class="upload-demo"
            :action="$mainUrl('api/bannerEdit')"
            ref="xgupload"
            list-type="picture"
            :data="{
              id: changeObj.id,
              title: changeObj.title,
              name: this.$store.state.userInfo.name,
              token: this.$store.state.userInfo.token,
            }"
            :on-change="xgchuan"
            :on-success="xgyes"
            :on-error="xgno"
            :file-list="changeObj.file"
            :limit="1"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >选取文件</el-button
            >
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="changeObj.flag = false">取 消</el-button>
        <el-button type="primary" @click="changeTrue">确 定</el-button>
      </div>
    </el-dialog>

    <el-pagination
      v-if="tableData"
      background
      layout="sizes, prev, pager, next, jumper"
      :total="fenye.total"
      :page-sizes="[10, 20, 30, 50]"
      @current-change="yebian"
      @size-change="tiaobian"
      :page-size="fenye.page_size"
      :current-page="fenye.current_page"
    >
    </el-pagination>
  </div>
</template>

<script>
import vueQr from "vue-qr";
export default {
  //将每组需要操作的数据，分成每个对象，在方法里进行再次的处理
  data() {
    return {
      //公用部分
      tableData: [{ name: "sad" }], //列表的数据
      space: "", //地址的数据（从vuex里获得）
      userList: [], //用户的列表信息
      loading: true,

      search: "", //搜索用
      top_sou: "",
      addObj: {
        //添加用
        title: "",
        files: [],
        flag: false,
      },
      fenye: {
        //分页用
        total: 1, //总条数
        page_size: 10, //单页条数
        current_page: 1, //页码
      },
      changeObj: {
        //编辑用
        flag: false,
        title: "",
        file: [],
        id: "",
      },
      imageUrl: "",
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      console.log("beforeAvatarUpload", file);
    },
    handleSuccess(res, file) {
      // this.formData.data[prop]=
      console.log("上传成功", res, file, URL.createObjectURL(file.raw));
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    //编辑按钮
    handleEdit(index, row) {
      this.changeObj.flag = true;
      this.changeObj.title = this.tableData[index].title;
      this.changeObj.file = [
        {
          name: this.tableData[index].title,
          url: this.$mainUrl(this.tableData[index].img),
        },
      ];
      this.changeObj.id = this.tableData[index].id;
      console.log(row, this.changeObj);
    },
    //编辑确定按钮
    changeTrue() {
      this.loading = true;
      let that = this;

      // 不为空提示
      if (this.changeObj.title.length == 0 || this.changeObj.file == []) {
        that.$message({
          message: "请检查表单内容",
          type: "error",
        });
      } else {
        this.$refs.xgupload.submit();
        this.shebeiList(this.fenye.current_page, this.fenye.page_size);
      }
    },
    //删除按钮
    handleDelete(id) {
      let that = this;

      this.$confirm("确认删除吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            name: this.$store.state.userInfo.name,
            token: this.$store.state.userInfo.token,
            id: id,
          };
          this.$axios.post("/bannerDel", obj).then(function (res) {
            if (res.data.code == "1") {
              that.$message({
                type: "info",
                message: res.data.message,
              });
            } else {
              that.$message({
                type: "success",
                message: res.data.message,
              });
              that.shebeiList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //分页
    yebian(currentPage) {
      //页面的页码发生变化的时候
      this.loading = true;
      this.fenye.current_page = currentPage;
      this.shebeiList(currentPage, this.fenye.page_size);
    },
    tiaobian(ind) {
      //页面的条数发生变化的时候
      this.loading = true;
      this.fenye.page_size = ind;
      this.shebeiList(this.fenye.current_page, ind);
    },
    xgchuan(file, FileList) {
      this.changeObj.file = FileList;
    },
    addclear() {
      this.addObj.flag = false;
      this.addObj.title = "";
      this.addObj.files = [];
    },
    //创建用户的确定按钮
    addTrue() {
      if (this.addObj.title.length == 0) {
        this.$message({
          message: "请填写标题",
          type: "error",
        });
      } else if (this.addObj.files.length == 0) {
        this.$message({
          message: "请上传图片",
          type: "error",
        });
      } else {
        this.loading = true;

        this.$refs.upload.submit();
        this.imageUrl = "";
        this.addclear();

        // let that = this
        this.addObj.flag = false;
        this.shebeiList();
      }
    },
    //在添加完成或取消，消除内容
    addyes(res, file, fileList) {
      console.log("上传成");
      this.imageUrl = URL.createObjectURL(file.raw);
      this.addObj.files = fileList;
      this.addObj.title = this.addObj.title;

      this.$message({
        message: "上传成功",
        type: "success",
      });
    },
    addno() {
      this.$refs.upload.submit();
      this.$message({
        message: "上传失败，请检查您的网络",
        type: "error",
      });
    },
    //在修改完成或取消，消除内容
    xgyes() {
      this.changeObj.title = "";
      this.changeObj.flag = false;
      this.$refs.xgupload.submit();
      this.$message({
        message: "修改成功",
        type: "success",
      });
      this.shebeiList();
    },
    xgno() {
      this.$message({
        message: "修改失败",
        type: "error",
      });
      this.shebeiList();
    },
    //门店设备列表
    shebeiList(ind, num) {
      let that = this;
      let obj = {
        page: ind,
        pageSize: num,
        name: this.$store.state.userInfo.name,
        token: this.$store.state.userInfo.token,
      };
      this.$axios.post("/banner", obj).then(function (res) {
        if (res.data.code == "0") {
          that.fenye.total = res.data.pageCount * res.data.pageSize;
          that.tableData = res.data.data;
          that.loading = false;
        } else if (res.data.code == "2") {
          that.$message({
            message: res.data.message + "请重新登录",
            type: "error",
          });
          that.loading = false;
        } else if (res.data.code == "3") {
          that.$message({
            message: res.data.message,
            type: "error",
          });
          that.loading = false;
        }
      });
    },
  },
  //挂载完成执行的生命钩子函数
  created() {
    //获得三级菜单，处理数据
    this.space = this.$store.state.space;
    //获得门店主列表信息
    let that = this;
    let obj = {
      name: this.$store.state.userInfo.name,
      token: this.$store.state.userInfo.token,
    };
    that.shebeiList(that.fenye.current_page, that.fenye.page_size);
  },
  components: {
    vueQr,
  },
};
</script>

<style lang="less">
#container {
  padding-right: 10px;
}
.top {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
